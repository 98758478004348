import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getCountryVat } from 'common/constants/countryVatList'
import { FieldSlugEnum } from 'common/enums/FieldSlugEnum'
import {
  paymentMethodsWithPersonalTaxNumber,
  PaymentMethodEnum,
} from 'common/enums/PaymentMethodEnum'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { selectors as managementSel } from 'publisher/hooks/useManagement'
import useOptIn, { selectors as optInSelectors } from 'publisher/hooks/useOptIn'
import { usePayment, usePage, useManagement } from 'publisher/store'
import { useOptIn as useTypedOptIn } from 'publisher/store'
import managementSelectors from 'publisher/store/management/managementSelectors'
import { getFieldValueBySlug } from 'publisher/store/optIn/optInSelectors'
import pageSelectors from 'publisher/store/page/pageSelectors'
import paymentSelectors from 'publisher/store/payment/paymentSelectors'
import { useRecaptchaValidation } from './useRecaptchaValidation'

export type PaymentRequestPayload = {
  customer: {
    fields: Record<FieldSlugEnum, string> // add type after refactoring opt-in
    popupId: string | null // will be removed
    isDesktop: boolean // will be removed
  }
  paymentMethod: string
  offerPricePlan?: number
  orderBumpOffers: number[]
  coupon?: number
  product?: {
    id?: number
    variant?: string
    quantity?: number
  }
  popupId: string | null
  isDesktop: boolean
  captcha?: string
}

type PaymentHandlerType = (body: PaymentRequestPayload) => Promise<void>

export default function usePaymentSubmit(entity: OldEntityInterface) {
  const { t } = useTranslation()
  const [isLoading, setLoading] = useState(false)
  const fields = useOptIn(optInSelectors.getFields)
  const [errors, setErrors] = useState<string[]>([])
  const pricePlans = usePayment(paymentSelectors.getPricePlans)
  const checkedPricePlanId = usePayment(paymentSelectors.getCheckedPricePlanId)
  const coupon = usePayment(paymentSelectors.getCheckedCoupon)
  const checkedBumpIds = usePayment(paymentSelectors.getCheckedBumpIds)
  const paymentMethod = usePayment(paymentSelectors.getActivePaymentMethod)
  const isCustomerPersonal = usePayment(paymentSelectors.isCustomerPersonal)
  const popupId = usePage(p => pageSelectors.getAscendantPopupId(p, entity))
  const isDesktop = useManagement(managementSelectors.isDesktop)
  const confirmedExplicitConsentsIds = useManagement(
    managementSel.getConfirmedExplicitConsentsIds,
  )
  const product = usePayment(paymentSelectors.getProduct)
  const productQuantity = usePayment(paymentSelectors.getProductQuantity)
  const activeProductVariant = usePayment(
    paymentSelectors.getProductActiveVariant,
  )
  const mandatoryExplicitConsents = usePage(p =>
    pageSelectors.getVisibleMandatoryExplicitConsents(p, isDesktop, popupId),
  )
  const customerCountryCode = useTypedOptIn(optIn =>
    getFieldValueBySlug(optIn, FieldSlugEnum.Country),
  )
  const isTaxFieldIsDisabled = getCountryVat(customerCountryCode) === null

  const { validateRecaptchas } = useRecaptchaValidation(entity)

  const handlePayment = async (paymentMethodHandler: PaymentHandlerType) => {
    setErrors([])
    const arePricePlansEmpty = pricePlans.length === 0
    //
    if (arePricePlansEmpty && !product?.id) {
      setErrors([
        // eslint-disable-next-line no-undef
        ...new Set(errors.concat([t('validation.empty_price_plans')])),
      ])
      return
    }
    if (!paymentMethod) {
      setErrors([
        // eslint-disable-next-line no-undef
        ...new Set(errors.concat([t('validation.empty_payment_methods')])),
      ])
      return
    }
    const explicitConsentsErrors = mandatoryExplicitConsents.reduce(
      (errors, explicitConsent) => {
        if (!('options' in explicitConsent)) {
          return errors
        }

        return confirmedExplicitConsentsIds.includes(explicitConsent.id)
          ? errors
          : [...errors, explicitConsent.options.errorText]
      },
      [] as string[],
    )
    if (explicitConsentsErrors.length > 0) {
      setErrors(explicitConsentsErrors)
      return
    }
    const { recaptchaToken, error } = validateRecaptchas()

    if (error) return

    if (isCustomerPersonal) {
      delete fields[FieldSlugEnum.CompanyName]
      if (
        !paymentMethodsWithPersonalTaxNumber.includes(
          paymentMethod as PaymentMethodEnum,
        )
      ) {
        delete fields[FieldSlugEnum.TaxNumber]
      }
    }

    if (
      !paymentMethodsWithPersonalTaxNumber.includes(
        paymentMethod as PaymentMethodEnum,
      ) &&
      isTaxFieldIsDisabled
    ) {
      delete fields[FieldSlugEnum.TaxNumber]
    }
    // todo we need to know why can't validate company name on a client side
    // const emptyFieldErrors: Record<string, string[]> = validateEmptyOptInFields(
    //   visibleOptInFields,
    //   t,
    // )
    // if (Object.keys(emptyFieldErrors).length) {
    //   dispatch(optInFail({ fields: emptyFieldErrors }))
    //   return
    // }
    const calculatedFormProperties = product
      ? {
          product: {
            id: product?.id,
            variant: activeProductVariant?.id,
            quantity: productQuantity,
          },
          orderBumpOffers: checkedBumpIds,
          ...(coupon ? { coupon: coupon.id } : {}),
        }
      : {
          offerPricePlan: checkedPricePlanId,
          orderBumpOffers: checkedBumpIds,
          ...(coupon ? { coupon: coupon.id } : {}),
        }
    setLoading(true)
    await paymentMethodHandler({
      customer: {
        fields,
        popupId,
        isDesktop,
      },
      popupId,
      isDesktop,
      paymentMethod,
      ...calculatedFormProperties,
      captcha: recaptchaToken,
    })

    setLoading(false)
  }

  return {
    errors,
    setErrors,
    isLoading,
    setLoading,
    submit: handlePayment,
    fields,
  }
}
