import axios from 'axios'
import { BumpPaymentPayloadInterface } from '../paymentProcessors/mercadoPago/types/BumpPaymentPayloadInterface'
import { UpsellPaymentPayloadInterface } from '../paymentProcessors/mercadoPago/types/UpsellPaymentPayloadInterface'
import { PageId } from '../store/page/PageStateInterface'
import { PurchaseProcessId } from '../store/payment/PaymentStateInterface'

export function buyMainOffer(
  pageId: PageId,
  purchaseProcessId: PurchaseProcessId,
  body: any,
) {
  return axios.post(
    `/secure/mercado-pago/${pageId}/buy-main-offer/${purchaseProcessId}`,
    body,
  )
}

export function buyOfferBump(
  pageId: PageId,
  purchaseProcessId: PurchaseProcessId,
  payload: BumpPaymentPayloadInterface,
) {
  return axios.post(
    `/secure/mercado-pago/${pageId}/buy-bump-offer-card/${purchaseProcessId}`,
    payload,
  )
}

export function buyUpsellOfferCard(
  pageId: PageId,
  purchaseProcessId: PurchaseProcessId,
  payload: UpsellPaymentPayloadInterface,
) {
  return axios.post(
    `/secure/mercado-pago/${pageId}/buy-upsell-offer-card/${purchaseProcessId}`,
    payload,
  )
}
