import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { BadRequest, InternalError, NetworkError } from 'common/errors'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { optInFail } from 'publisher/actions/optInActions'
import { ThreeDsInfo } from 'publisher/paymentProcessors/mercadoPago/components/ThreeDsModal'
import {
  MercadoPagoServerError,
  useMercadoPago,
} from 'publisher/context/mercadoPagoContext'
import usePage, { selectors as pageSelectors } from 'publisher/hooks/usePage'
import { usePayment } from 'publisher/store'
import { buyMainOffer, buyOfferBump } from '../api/mercadoPagoApi'
import paymentSelectors from '../store/payment/paymentSelectors'
import usePaymentSubmit from './usePaymentSubmit'

export default function useMercadoPagoPaymentHandler(
  entity: OldEntityInterface,
) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { errors, setErrors, isLoading, setLoading, submit } =
    usePaymentSubmit(entity)
  const purchaseProcessId = usePayment(paymentSelectors.getPurchaseProcessId)
  const pageId = usePage(pageSelectors.getPageId)
  const { generatePaymentData } = useMercadoPago()

  const handleSubmit = async (
    e: React.SyntheticEvent,
    handleThreeDs: (
      threeDsInfo: ThreeDsInfo,
      orderBumpOffers: number[],
      redirect: string,
    ) => void,
  ) => {
    e.preventDefault()

    setLoading(true)
    setErrors([])

    const paymentData = await generatePaymentData()

    if (!paymentData) {
      setErrors([t('core.errors.something_went_wrong')])
      setLoading(false)
      return
    }

    submit(async body => {
      try {
        if (window.Rollbar) {
          window.Rollbar.info('mercado pago paymentData', {
            paymentData: paymentData,
          })
          window.Rollbar.captureEvent({ paymentData: paymentData }, 'info')
        }
        const { data } = await buyMainOffer(pageId, purchaseProcessId, {
          payment_form: {
            ...body,
            token: paymentData.token,
            mercadoPagoPaymentMethod: paymentData.paymentMethodId,
            installments: paymentData.installments,
          },
        })

        if (data.threeDsInfo) {
          handleThreeDs(data.threeDsInfo, body.orderBumpOffers, data.redirect)
          return
        }

        if (data.redirect) {
          if (body.orderBumpOffers.length > 0) {
            const bumpPaymentData = await generatePaymentData()
            if (bumpPaymentData) {
              await buyOfferBump(pageId, purchaseProcessId, {
                bump_payment_form: {
                  token: bumpPaymentData.token,
                  mercadoPagoPaymentMethod: bumpPaymentData.paymentMethodId,
                  installments: bumpPaymentData.installments,
                },
              })
            }
          }

          window.location.assign(data.redirect)
          return
        }
      } catch (error) {
        if (error instanceof BadRequest) {
          setErrors(error.response.data.errors.common)
          dispatch(optInFail({ fields: error.response.data.errors.fields }))
        } else if (error instanceof NetworkError) {
          setErrors([t('core.errors.no_connection')])
        } else if (error instanceof InternalError) {
          setErrors([t('core.error.title')])
          if (window.Rollbar) {
            window.Rollbar.error(
              `Mercado pago internal error ${JSON.stringify(error)}`,
            )
          } else {
            console.log('Mercado pago internal error', error)
          }
        } else {
          if (window.Rollbar) {
            window.Rollbar.error(
              `Mercado pago undefined server error ${JSON.stringify(error)}`,
            )
          }
        }
        // catch MP errors
        // @ts-ignore
        if (error.cause) {
          const cardFormErrors: Record<string, string> = {}
          ;(error as MercadoPagoServerError).cause.forEach(cause => {
            switch (cause.code) {
              case 'E205':
                cardFormErrors['cardExpirationYear'] = t(
                  'validation.expiration_invalid',
                )
                break
              default:
                if (window.Rollbar) {
                  window.Rollbar.error(
                    'Undefined Mercado pago token handling error',
                    errors,
                  )
                }
                console.error('undefined mercado pago error', error)
            }
          })
          setErrors(Object.values(cardFormErrors))
        }
      }
    })
  }

  return { errors, isLoading, setErrors, handleSubmit }
}
