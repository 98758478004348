import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { BadRequest, InternalError, NetworkError } from 'common/errors'
import { optInFail } from 'publisher/actions/optInActions'
import { buyMainOffer } from 'publisher/api/paypalRestApi'
import { pageSelectors } from 'publisher/store'
import paymentSelectors from 'publisher/store/payment/paymentSelectors'
import { OldEntityInterface } from '../../common/types/entities/OldEntityInterface'
import usePage from './usePage'
import usePayment from './usePayment'
import usePaymentSubmit from './usePaymentSubmit'

const usePaypalRestPaymentHandler = (entity: OldEntityInterface) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { errors, setErrors, isLoading, submit } = usePaymentSubmit(entity)
  const purchaseProcessId = usePayment(paymentSelectors.getPurchaseProcessId)
  const pageId = usePage(pageSelectors.getPageId)

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    submit(async body => {
      try {
        const { data } = await buyMainOffer(pageId, purchaseProcessId, {
          payment_form: body,
        })

        if (data.redirect) {
          window.location.assign(data.redirect)
          return
        }
      } catch (error) {
        if (error instanceof BadRequest) {
          setErrors(error.response.data.errors.common)
          dispatch(optInFail({ fields: error.response.data.errors.fields }))
        } else if (error instanceof NetworkError) {
          // @ts-ignore
          setErrors([t('core.errors.no_connection')])
        } else if (error instanceof InternalError) {
          // @ts-ignore
          setErrors([t('core.error.title')])
        }
      }
    })
  }

  return { errors, isLoading, handleSubmit }
}

export default usePaypalRestPaymentHandler
