import React, { useCallback, useState } from 'react'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import ThreeDsModal, {
  ThreeDsInfo,
} from 'publisher/paymentProcessors/mercadoPago/components/ThreeDsModal'
import useMercadoPagoPaymentHandler from 'publisher/hooks/useMercadoPagoPaymentHandler'
import FieldErrors from '../../FieldErrors'
import BaseButton from '../BaseButton'

function MercadoPagoCreditCardPaymentButton({
  entity,
}: {
  entity: OldEntityInterface
}) {
  const { styles, mobileStyles, options, mobileOptions = {} } = entity
  const { errors, isLoading, setErrors, handleSubmit } =
    useMercadoPagoPaymentHandler(entity)

  const [isThreeDsModalOpen, setIsThreeDsModalOpen] = useState(false)
  const [redirect, setRedirect] = useState<string>()
  const [orderBumpOffers, setOrderBumpOffers] = useState<number[]>([])
  const [threeDsInfo, setThreeDsInfo] = useState<ThreeDsInfo>({
    creq: '',
    external_resource_url: '',
  })

  const handleThreeDs = useCallback(
    (threeDsData: ThreeDsInfo, bumpOffers: number[], redirect?: string) => {
      setThreeDsInfo(threeDsData)
      setRedirect(redirect)
      setOrderBumpOffers(bumpOffers)
      setIsThreeDsModalOpen(true)
    },
    [],
  )

  const onThreeDsClose = useCallback((errors?: string[]) => {
    setIsThreeDsModalOpen(false)
    if (errors && errors.length) {
      setErrors(errors);
    }
  }, [setErrors])

  const handleMercadoSubmit = useCallback(
    (e: React.SyntheticEvent) => handleSubmit(e, handleThreeDs),
    [handleSubmit, handleThreeDs],
  )

  return (
    <>
      <BaseButton
        handleClick={handleMercadoSubmit}
        disabled={isLoading}
        styles={styles}
        mobileStyles={mobileStyles}
        attrId={options.attrId}
        text={options.text}
        borderType={options.borderType}
        mobileBorderType={mobileOptions.borderType}
        appearance={options.appearance}
        loading={isLoading}
        loadingText={options.loadingText}
        subText={options.subText}
        subTextColor={options.subTextColor}
        subTextFontSize={options.subTextFontSize}
        subTextFontFamily={options.subTextFontFamily}
        mobileSubTextColor={mobileOptions.subTextColor}
        mobileSubTextFontSize={mobileOptions.subTextFontSize}
        mobileSubTextFontFamily={mobileOptions.subTextFontFamily}
        iconClassNameBefore={options.iconClassNameBefore}
        iconClassNameAfter={options.iconClassNameAfter}
        hoverStyles={options?.hover}
        hoverMobileStyles={mobileOptions?.hover}
      />
      <FieldErrors errors={errors} align="center" />
      {isThreeDsModalOpen && (
        <ThreeDsModal
          creq={threeDsInfo.creq}
          external_resource_url={threeDsInfo.external_resource_url}
          redirect={redirect}
          orderBumpOffers={orderBumpOffers}
          onClose={onThreeDsClose}
        />
      )}
    </>
  )
}

export default MercadoPagoCreditCardPaymentButton
