import { createPortal } from 'react-dom'
import React, { useEffect, useRef, useState } from 'react'
import { Dialog } from 'client/components/core/Dialog'
import { buyOfferBump } from 'publisher/api/mercadoPagoApi'
import { useMercadoPago } from 'publisher/context/mercadoPagoContext'
import usePage, { selectors as pageSelectors } from 'publisher/hooks/usePage'
import { usePayment } from 'publisher/store'
import paymentSelectors from 'publisher/store/payment/paymentSelectors'
import { ThreeDsWrapper } from './ui/three-ds-wrapper'

export type ThreeDsInfo = {
  creq: string
  external_resource_url: string
}

export interface ThreeDsModalProps extends ThreeDsInfo {
  orderBumpOffers?: number[]
  redirect?: string
  onClose: (errors?: string[]) => void
}

const ThreeDsModal = ({
  creq,
  external_resource_url,
  redirect,
  orderBumpOffers = [],
  onClose,
}: ThreeDsModalProps) => {
  const [threeDsInfo, setThreeDsInfo] = useState<ThreeDsInfo>({
    creq,
    external_resource_url,
  })

  const threeDsFormRef = useRef<HTMLFormElement>(null)
  const purchaseProcessId = usePayment(paymentSelectors.getPurchaseProcessId)
  const pageId = usePage(pageSelectors.getPageId)
  const { generatePaymentData } = useMercadoPago()
  const [isBumpHandled, setIsBumpHandled] = useState<boolean>(false)

  useEffect(() => {
    const handleThreeDsSubmit = (e: MessageEvent) => {
      if (e.origin !== 'https://api.mercadopago.com') return

      if (e.data.status === 'COMPLETE') {
        const handleBumps = async () => {
          const bumpPaymentData = await generatePaymentData()
          if (bumpPaymentData) {
            const { data } = await buyOfferBump(pageId, purchaseProcessId, {
              bump_payment_form: {
                token: bumpPaymentData.token,
                mercadoPagoPaymentMethod: bumpPaymentData.paymentMethodId,
                installments: bumpPaymentData.installments,
              },
            })

            if (data.threeDsInfo) {
              setThreeDsInfo(data.threeDsInfo)
              setIsBumpHandled(true)
            }
          }
        }

        if (orderBumpOffers.length > 0 && !isBumpHandled) {
          handleBumps()
        } else {
          redirect ? window.location.assign(redirect) : onClose()
        }
      }
    }

    if (!threeDsFormRef.current) return

    threeDsFormRef.current.requestSubmit()
    window.addEventListener('message', handleThreeDsSubmit)

    return () => window.removeEventListener('message', handleThreeDsSubmit)
  }, [
    isBumpHandled,
    orderBumpOffers,
    threeDsInfo,
    generatePaymentData,
    pageId,
    purchaseProcessId,
    redirect,
    onClose,
  ])

  return createPortal(
    <Dialog id="three-ds-modal" show close={null} small={false} width="100%">
      <ThreeDsWrapper>
        <iframe name="mercado-3ds-iframe" id="mercado-3ds-iframe">
          <form
            name="mercado-3ds-form"
            target="mercado-3ds-iframe"
            method="post"
            action={threeDsInfo.external_resource_url}
            ref={threeDsFormRef}
          >
            <input type="hidden" name="creq" value={threeDsInfo.creq} />
          </form>
        </iframe>
      </ThreeDsWrapper>
    </Dialog>,
    document.body,
  )
}

export default ThreeDsModal
